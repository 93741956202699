import React, { useRef, useState, useEffect, Component } from "react"
import ReactPlayer from 'react-player'
import ReactWebMediaPlayer from "react-web-media-player"

import styled from "styled-components"
import HoverVideoPlayer from 'react-hover-video-player';


const BeforeAndAfterVideo = (props) => {
  const ref = useRef(null);
  const [focus, setFocus] = useState(false);
  const [url, setUrl] = useState("");

  const loop = () => {
    // ref.current.play();
  };

  const onEndedLoop = () => {
    if (focus) loop(); // when ended check if its focused then loop
  };

  useEffect(() => {
    setUrl(props.url)
    console.log("props.url")
    console.log(props.url)
    if (focus) loop(); // when focused then loop
  }, [focus]);

  return (
    <Container>
        <H1Text>{props.state}</H1Text>
          <Content>
           <HoverVideoPlayer
                videoSrc={props.url}
                pausedOverlay={
                  <img
                    src={props.thumbnail}
                    alt=""
                    style={{
                      // Make the image expand to cover the video's dimensions
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                }
                loadingOverlay={
                  <div className="loading-overlay">
                    <div className="loading-spinner" />
                  </div>
                }
              />
          {/*  <ReactPlayer
             light={"http://placekitten.com/200/300"}
              id="video"
              ref={ref}
              autoPlay={true}
              onMouseOver={() => setFocus(true)}
              onMouseOut={() => setFocus(false)}
              muted={true}
              onEnded={onEndedLoop}
              url={'https://player.vimeo.com/video/739118323?h=da79c3cb4'} 
              />           */}
          </Content>

    </Container>
  )
}

export default BeforeAndAfterVideo

const Container = styled.div`
  position: relative;
  justify-content:center;
  align-content:center;
  max-height: 600px;
  @media (max-width: 600px) {
      max-height: 600px;
      
  }
  // border-radius: 25px;
  // border: 5px solid white;
`

/*display: grid;*/
/*    grid-template-columns: repeat(2, auto);*/
/*min-height:400px;*/
const Content = styled.div`
  border-radius:32px;
  overflow:hidden;
  max-width: 100%;
  max-height: 400px;
  display: flex;
  
  justify-content: center;
  align-items: center;
  @media (max-width: 1576px) {
    

  }
`
const SubContent = styled.div`
  max-width: 100%;
  margin: 50px 100px 50px 100px;
  display: grid;
  grid-template-columns: repeat(2, auto);

  /*align vertical / justify is horizontal*/
  align-items: center;
  justify-items: center;
`

const MarContent = styled.div`
  margin: 0px 100px 0px 0px;
   @media (max-width: 1024px) {
    width:60%
    height:60%
  }
`
const H1Text = styled.h1`
  max-width: 450px;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-weight: normal;
  padding: 0px auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  bottom: 0;
    @media (max-width: 1024px) {
    font-size: 50px;
    padding:100px auto;
  }
`
