import React, { Fragment, useState, useEffect, Component } from "react"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"
import HoverVideoPlayer from 'react-hover-video-player';
import FooterContainer from "../components/FooterContainer"

import vectorBackground from "../assets/VectorBackground.svg"
import backgroundSlide from "../assets/BackgroundSlide.svg"
import wave from "../assets/Wave.svg"
import waveTop from "../assets/WaveTop.svg"
import Button from "react-bootstrap/Button"
import gatlinburg from "../images/gatlinburg.png"
// import gatlinburg_vid from "../images/video/gatlinburg_vid4.mov"


import Clock from "../assets/clock.png"
import Globe from "../assets/white-globe.jpeg"
import BeforeAndAfterVideo from "../components/BeforeAndAfterVideo"
import VimeoVideo from "../components/VimeoVideo"
import {isEmpty} from "lodash"

import ResponsiveAppBar from "../components/ResponsiveAppBar"
import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import pool_before from '../images/video/pool_before2.mp4'
import pool_after from '../images/video/pool_after.mp4'

import pool_before_img from '../images/poo_before_img2.jpeg'
import pool_after_img from '../images/pool_after_img.png'

const Video = props => {
  useEffect(() => {

  })

  const handleSumit = () => {
  	window.location.href = '/setup-a-meeting'
	}

	return (
		<>
			<GlobalFonts />
      <Background>
      <TopBackground src={topbackground} />
      </Background>
			<ResponsiveAppBar />
      <TitleTextHeader>Own A Vacation Rental</TitleTextHeader>
      <DetailTextHeader>We're experts in renovation. Below you can see what we do. </DetailTextHeader>
      <Hero3>
        <DetailText>Cloud Castles maximizes ROI by adding amenities to the property.</DetailText>
        <CTAButton onClick={handleSumit}>Speak To A Consultant</CTAButton>
      </Hero3>
            <Hero5>
            <VimeoVideo muted={true} url={"https://vimeo.com/756215415"}/>
      {/*      <HoverVideoPlayer
                videoSrc={gatlinburg_vid}
                style={{
                      // Make the image expand to cover the video's dimensions
                      width: '50%',
                      height: '50%',
                      objectFit: 'cover',
                      borderRadius: 33
                    }}
                pausedOverlay={
                  <img
                    src={gatlinburg}
                    alt=""
                    style={{
                      // Make the image expand to cover the video's dimensions
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                }
                loadingOverlay={
                  <div className="loading-overlay">
                    <div className="loading-spinner" />
                  </div>
                }
              />*/}
              <HowSubText1>Gatlinburg, TN Cloud Castle</HowSubText1>
              <HowSubText2>See how we completely renovated this house to be worth 5x the value.</HowSubText2>
          </Hero5>
			<Hero>
				<Hero1>          
					<BeforeAndAfterVideo state={"After"} thumbnail={pool_after_img} url={pool_after}/>
				</Hero1>
				<Hero1>					
					<BeforeAndAfterVideo state={"Before"} thumbnail={pool_before_img} url={pool_before}/>
				</Hero1>
			</Hero>
          <HowSubText1>Miami, FL Cloud Castle</HowSubText1>
          <HowSubText2>This house has a beautiful lap pool added to increase revenue.</HowSubText2>
    {/*  <TitleText style={{color:"black"}}>Tampa</TitleText>
        <Hero3>
        <DetailText>Cloud Castles maximizes ROI for by added amenities to the property.</DetailText>
        <CTAButton>Speak To A Consultant</CTAButton>
      </Hero3>
            <Hero>
        <Hero1>
          <BeforeAndAfterVideo state={"After"} thumbnail={"http://placekitten.com/200/300"} url={"https://vimeo.com/659196249"}/>

        </Hero1>
        <Hero1>
          
          <BeforeAndAfterVideo state={"After"} thumbnail={"http://placekitten.com/200/300"} url={"https://vimeo.com/659196249"}/>
        </Hero1>
      </Hero>*/}
			<FooterDiv>
				<FooterContainer />
			</FooterDiv>

		</>
	)
}

export default Video

const Background = styled.div`
  color:#8bbef3;
  width:100%;
  overflow-x: hidden;
  overflow: hidden;
`

const TopBackground = styled.img`
  width:100%;
  overflow-x: hidden;
  position: absolute;
  left: -34.63%;
  right: -49.75%;
  top: -40.3%;
  // bottom: 78.14%;
  z-index:-1;
  overflow-x: hidden;
  background: #8bbef3;
  border-radius: 333px;
  
  // transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
`
const Hero = styled.div`
	// background:#8bbef3;
  margin:0px;
  max-width: 100%;
  height:100%;
  overflow-x: hidden;

  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 10px auto;

  // padding-top:  100px;
  // padding-bottom:  100px;

  @media only screen and (max-width: 800px) and (min-width: 501px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
  }
  @media only screen and (max-width: 500px) and (min-width: 100px) {
    flex-direction: column-reverse;
    // margin: -70px 50px 50px -20px;
    max-width: 100%;
  }
`

const Hero1 = styled.div`
  
  display: flex;
// margin: 100px auto 0px 0px;
// padding 100px auto auto 100px;
	flex-direction:column;
  // justify-content: center;
  // align-content: center;
  margin: auto;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  width:45%;
	max-height:100%;
  @media only screen and (max-width:800px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    width:80%;
  }  

 
`


const Hero3 = styled.div`
  // margin-top:  auto;
  // margin-bottom:  auto;
  // margin-left:  auto;
  // margin-right:  30px;
  display: flex;
  width:100%;
  flex-direction: row;
  justify-content: flex-start;
  // align-items: row-reverse;
  // align-items:space-evenly;
`

const DetailTextHeader = styled.h1 `
  position: relative;
  // width: 80%;
  // height: 43.18px;
  margin-left:50px;

  font-family: Poppins;
  font-style: bold;
  font-weight: 300;
  font-size: 22.7436px;
  line-height: 140%;
  /* or 25px */

  color: #fafdff;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width:1400px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    color:  black;
    width:80%;
    margin-left:50px;
  }  
  
`

const TitleTextHeader = styled.h1 `
  position: relative;
  // width: 80%;
  // height: 43.18px;
  margin-left:50px;
  margin-top:100px;
  font-family: Poppins;
  font-style: bold;
  font-weight: 500;
  font-size: 37.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    @media only screen and (max-width:1320px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    color:  black;
    margin-left:50px;
    width:80%;
  }  
  
`

const TitleText = styled.h1 `
position: relative;
  // width: 80%;
  // height: 43.18px;
  margin-top:50px;
  margin-left:100px;
  font-family: Poppins;
  font-style: bold;
  font-weight: 500;
  font-size: 24.7436px;
  line-height: 151%;
  /* or 25px */

  color: #fafdff;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // height:50px;
    margin-left:50px;
  }
	
`
const DetailText = styled.h2`
position: relative;
  width: 30%;
  margin-right:100px;
  margin-left:100px;
  // height: 43.18px;
  margin-right:310px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14.7436px;
  line-height: 151%;
  /* or 25px */

  color: white;
    animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
    @media only screen and (max-width:1400px) and (min-width: 100px) {
    // margin: 100px auto 50px 100px;
    color:  black;
    width:100%;
    margin-left:50px;
    margin-right:0px;
  }  
`


const FooterDiv = styled.div`
	// position: relative;
	width: 100%;
	z-index: 3;
	// left: 0px;
	// top: 700px;
`


const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1461px;
  line-height: 24px;
  width:300px;  
  color: #ffffff;

  background: #c73967;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  margin-right:100px;
  @media only screen and (max-width: 1030px) and (min-width: 426px) {
    height:50px;
    margin-right:100px;
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    height:50px;
    margin:15px;
  }
`
const Hero5 = styled.div`
  // margin-top:  auto;
  // margin-bottom:  auto;
  margin:  15px;
  align-text:  center;
  width:100%;
  height:500px;
  
  // margin-right:  30px;
  display: flex;
  // // width:100%;
  flex-direction: column;
  justify-content: center;
  align-items:center;
`

const HowSubText1 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20.8802px;
  line-height: 100%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  // margin: 40px;
  margin: 20px;
  text-align:left;
  // margin-top: 80px;
  // padding: auto;

  color: black;

`

const HowSubText2 = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 15.8802px;
  line-height: 120%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  // margin: 40px;
  margin-left: 20px;
  text-align:left;
  // margin-top: 80px;
  // padding: auto;

  color: black;
  @media (max-width: 900px) {
   font-size: 15.8802px;
  }
`
